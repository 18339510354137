<template>
  <div class="equipment-work-order-detail-wrapper">
    <van-nav-bar
      title="工单"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <div class="equipment-work-order-info">
      <div class="work-order-title">
        <span>{{ info.workNo }}({{ info.ticketsTypeName }})</span>
      </div>
      <van-collapse v-model="activeName" accordion @change="changeCollapse">
        <van-collapse-item title="工单信息" name="1">
          <van-field
            readonly
            label="工单名称"
            :value="info.name"
            input-align="right"
          />
          <van-field
            readonly
            label="工单类型"
            :value="info.ticketsTypeName"
            input-align="right"
          />
          <van-field
            readonly
            label="是否应急维修"
            :value="info.emergencyType == 0 ? '否' : '是'"
            input-align="right"
          />
          <van-field
            readonly
            label="计划维修日期"
            :value="info.planMaintenanceDate"
            input-align="right"
          />
          <van-field
            readonly
            label="关联故障"
            :value="info.deviceFaultName"
            input-align="right"
          />
          <van-field
            readonly
            label="关联设备"
            :value="info.equipmentInfo"
            input-align="right"
          />
          <van-field
            readonly
            label="维修部门"
            :value="info.maintenanceDepartmentName"
            input-align="right"
          />
          <van-field
            readonly
            label="维修方案"
            :value="info.maintenancePlan"
            input-align="right"
          />
          <van-field
            readonly
            label="配件需求"
            :value="info.partsNeed"
            input-align="right"
          />
          <van-field
            readonly
            label="工具需求"
            :value="info.toolNeed"
            input-align="right"
          />
          <van-field
            readonly
            label="费用预估(元)"
            :value="info.cost"
            input-align="right"
          />
        </van-collapse-item>
        <van-collapse-item
          v-for="item in maintenances"
          :key="item.id"
          :title="item.departmentName"
          :name="item.id"
          :value="item.statusName"
        >
          <van-field
            readonly
            label="维修部门"
            :value="item.detail.departmentName"
            input-align="right"
          />
          <van-field
            readonly
            label="维修人"
            :value="item.detail.maintenanceUserNameStr"
            input-align="right"
          />
          <van-field
            readonly
            label="开始时间"
            :value="item.detail.startDate"
            input-align="right"
          />
          <van-field
            readonly
            label="结束时间"
            :value="item.detail.endDate"
            input-align="right"
          />
          <van-field
            readonly
            label="工时(人*小时)"
            :value="item.detail.workTime"
            input-align="right"
          />
          <van-field
            readonly
            label="维修费用(元)"
            :value="item.detail.cost"
            input-align="right"
          />
          <van-field
            readonly
            label="评分"
            :value="item.detail.score"
            input-align="right"
          />
          <van-field
            readonly
            label="过程记录"
            :value="item.detail.processRecord"
            input-align="right"
          />
          <van-field
            readonly
            label="辅助工作"
            :value="item.detail.subsidiaryWork"
            input-align="right"
          />
          <van-field
            readonly
            label="检修说明"
            :value="item.detail.maintenanceExplain"
            input-align="right"
          />
          <van-field
            readonly
            label="维修分析"
            :value="item.detail.maintenanceAnalyse"
            input-align="right"
          />
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>

<script>
import {
  getTicketById,
  getTicketMaintenanceById,
  getMaintenanceById
} from "@/api/basics/equipment";
export default {
  props: {
    id: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      activeName: 1,
      info: {},
      maintenances: []
    };
  },
  created() {
    this.getData();
    this.getTicketMaintenanceById();
  },
  methods: {
    changeCollapse(v) {
      if (v == 1 || !v) {
        return;
      }
      this.getMaintenanceById(v);
    },
    // 查询工单信息
    getData() {
      getTicketById(this.id).then(res => {
        this.info = res;
      });
    },
    // 查询维修记录
    getTicketMaintenanceById() {
      getTicketMaintenanceById(this.id).then(res => {
        res.map(a => {
          a.detail = {};
        });
        this.maintenances = res;
      });
    },
    // 查询维修记录详情
    getMaintenanceById(id) {
      getMaintenanceById(id).then(res => {
        const obj = this.maintenances.find(a => a.id == id);
        if (obj) {
          if (res.maintenanceUserName) {
            res.maintenanceUserNameStr = res.maintenanceUserName.join(",");
          }
          obj.detail = res;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.equipment-work-order-detail-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  .equipment-work-order-info {
    flex: 1;
    height: 0;
    overflow-y: auto;
    .work-order-title {
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #333;
    }
  }
}
</style>
